import { algoliaClient, feathers as api, socket } from "../api";
export const search = (queries) => {
  return new Promise((resolve, reject) => {
    algoliaClient.search(queries, (err, { results } = {}) => {
      if (err) return reject(err);
      resolve(results);
    });
  });
};

export const getService = (service) => api.service(service);
export const savedListings = api.service("user-saved-listings");
export const userService = api.service("users");
export const recoveryPassword = api.service("recovery-password");
export const changePassword = api.service("change-password");
export const mylistings = api.service("my-listings");
export const reAuthenticate = api.reAuthenticate;

export const Logout = api.logout;
export const current = () => api.service("current-user").find({});

export const authenticate = ({
  strategy = "local",
  email,
  password,
  ...rest
}) => {
  return api.authenticate({
    strategy,
    email,
    password,
    ...rest,
  });
};
export const tasks = socket.service("tasks");
export const LogActions = socket.service("log-actions");
export const getSocket = (service) => socket.service(service);
